.pos-refresh-modal{
    max-width: 40% !important;
}
.help-modal{
    max-width: 30% !important;
}


@media only screen and (min-height: 1900px) {
    
    .pos-refresh-modal{
        max-width: 70% !important;
    }
    
    
    .help-modal{
        max-width: 60% !important;
    }
    

}
.custom-confirm-btn-modal{
    font-size: 18px;
    border-radius: 10px;
    max-width: 90px;
    min-width: 90px;
    height: 50px;
}