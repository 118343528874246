.search-input {
   border-radius: 0.625rem !important;
}

.search-input::placeholder {
   color: var(--kt-input-solid-color) !important;
   opacity: 1;
   /* Firefox */
}

.search-input::-ms-input-placeholder {
   /* Edge 12-18 */
   color: var(--kt-input-solid-color) !important;
}

#keypad #buttons {
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;   
}

#keypad button {   
   box-sizing: border-box;
   width: 30%;
   margin: 1.63% 1.63%;
   height: 80px;
   font-size: 3em;
   font-family: monospace;
   display: flex;
   align-items: center;
   justify-content: center;
   
}


#keypad {
   min-width: 400px;
   margin: 0 auto;
   overflow: auto;
}
.keypad_footer {
   display: flex;
   justify-content: end;   
}
.keypad_footer .clear_btn {
   background-image: url(../../../../../public/media/icons/duotune/arrows/arr015.svg);
   background-repeat: no-repeat;
   background-position: center;
}

.custom-stickybox > .input-group {
   padding: 20px;
   background: var(--kt-card-bg);
}

.tns-inner {
   margin-bottom: 1rem !important;
}

.slick-list {
   height: 82.2vh !important;
}

#div_scroll{
   scroll-behavior: smooth;
}