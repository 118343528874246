.counterWrapper {
   margin-top: 10px;
}

.plusminus {
   height: 100%;
   width: 30%;
   border: none;
   font-size: 22px;
   color: #104a93;
   background-color: var(--kt-body-bg) !important;
}

.num {
   text-align: center;
   font-size: 22px;
   color: var(--kt-input-solid-color) !important;
   border: none;
}
