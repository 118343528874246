#CameraPage {
  text-align: center;
  .centerFaceText{
    color: white;
    z-index: 1;
    font-size: 40px !important;
    //margin-top: -50px;
    position: relative;
    top: 76px;
  }
  .video-selfie {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(calc((100% - 100vw) / 2)) rotateY(180deg);
  }

  .svgClass {
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    right: 0;
    bottom: 0;
  }

  .button{
    position: absolute;
    bottom: 100px;
    z-index: 1;
    width: 50%;
    bottom: 10%;
    span {
      font-size: 20px;
    }
  }

  

 /* .background {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    text-align: center;

    .oval{
      width: 220px;
      height: 100px;
      margin:10px 0 0 -10px;
      background: transparent;
      -moz-border-radius: 100px / 50px;
      -webkit-border-radius: 100px / 50px;
      border-radius: 100px / 50px;
    }
  }*/
}
.xClose{
    color: #ffff;
    z-index: 1;
    font-size: 25px;
    margin-top: -90px;
    cursor: pointer;
    right: 9%;
    position: absolute;
}

#feed {
  position: relative;
  width: 50px; /* for demo purposes */
  padding: 10px;
}
#feed .top, #feed .bottom {
  position: absolute;
  width: 100px;
  height: 100px;
  pointer-events: none;
}

#feed .top {
  top: 20px;
  border-top: 3px solid #FFF;
  border-radius: 5px;
}

#feed .bottom {
  bottom: 20px;
  border-bottom: 3px solid #FFF;
  border-radius: 5px;
}

#feed .left {
  left: 20px;
  border-left: 3px solid #FFF;
  border-radius: 5px;
}

#feed .right {
  right: 20px;
  border-right: 3px solid #FFF;
  border-radius: 5px;
}

#overlayContainer {
  position: absolute;
  width: 98.3%;
  height: 98.6%;
}

.overlay{
  position: absolute;
  width: 98.3%;
  height: 98.6%;
}