$theme-white: #ffffff !default;
$theme-black: #000000 !default;
$gray-200-dark: #2b2b40 !default;
$gray-200: #eff2f5 !default;
$theme-primary: #3699ff !default;
$theme-primary-active: #187de4 !default;
$theme-primary-light: #f1faff !default;
$theme-primary-light-dark: #212e48 !default;
$theme-primary-inverse: $theme-white !default;
$form-check-input-bg-solid-dark: $gray-200-dark !default;
$form-check-input-bg-solid: $gray-200 !default;

:root {
   --kt-theme-default-primary: #3699ff;
   [data-theme='light'] {
      --kt-form-check-input-bg-solid: #eff2f5;
   }
   [data-theme='dark'] {
      --kt-form-check-input-bg-solid: #eff2f5;
   }
}

.toast-container .toast-header{  
   background-color: var(--kt-success) !important;
   color: var(--kt-danger-inverse) !important;
   border-radius: 5px;
}

.bg-primary {
   background-color: #fff !important;
   --bg-color: 54, 153, 255 !important;
}
.text-gray-400 {
   color: #474761 !important;
}
.text-right {
   text-align: right !important;
}
.text-center {
   text-align: center !important;
}
.coupon-bg {
   background-size: 70% 100%;
   background-image: url(../public/media/images/offer.jpg);
}
.hadder-banner-bg {
   background-size: 100% 100%;   
}
.big-header .hadder-banner-bg{
   height: 600px;
}
.mt-50 {
   margin-top: 10rem !important;
}
.w-33 {
   width: 33% !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
   display: none;
 }
 
 /* Hide scrollbar for IE, Edge and Firefox */
 .hide-scrollbar {
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
 }

@media only screen and (min-width: 320px) and (max-width: 767px) {
   .border-bigw {
      border-bottom: 2px solid;
      width: 75% !important;
   }
   .border-bigw img {
      margin-bottom: 20px !important;
      width: 80px;
   }
}
@media (min-width: 768px) {
   .border-bigw {
      border-bottom: 2px solid;
   }
}
.custom-rounded-modal {
   border-radius: 8% !important;
   padding: 3% !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
   .custom-btn-right,
   .custom-btn-left {
      text-align: center;
   }
}
@media only screen and (min-width: 480px) {
   .custom-btn-right {
      text-align: right;
   }

   .custom-btn-left {
      text-align: left;
   }
}
.image-text-container {
   width: 100%;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
   .image-text-container {
      margin-left: -1.25rem !important;
      width: 100vw !important;
   }
}

.pills-custom .show > .nav-link.nav-link-border-solid.active,
.nav-link.nav-link-border-solid.active {
   border: 3px solid var(--kt-primary);
}
.mb-vh-100{
   margin-bottom: 66vh;
}

.product-text {
   min-height: 51px !important;
}

.menu-cat-text {
   min-height: 51px !important;
}
//placing the swal alert styles above the media queries
.swal2-popup {
   background-color: var(--kt-body-bg) !important;
   width: auto;
}
.swal2-lg-custom-popup {
   width: 530px !important;
   height: 300px !important;
}
.swal2-styled.swal2-confirm {
   background-color: var(--kt-primary) !important;
}

.swal2-icon.swal2-warning {
   border-color: var(--kt-warning) !important;
   color: var(--kt-warning) !important;
}
.swal2-icon.swal2-error {
   border-color: var(--kt-danger) !important;
   color: var(kt-danger) !important;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
   background-color: rgba(246, 78, 96, 0.75) !important;
}

.btn-no-right-margin {
   margin-right: 0px !important;
}

.main-menu-item-div .card-body {
   padding: 2rem 2.25rem 0rem 2.25rem !important;   
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
   .main-menu-item-div {
     /*  height: 29rem !important; */
     min-height: 100%;
   }
}

@media only screen and (min-width: 767px) and (max-width: 1080px) {
   .main-menu-item-div {
      /* height: 23rem !important; */
      min-height: 100%;
   }
   .checkout-payment-modal {
      max-width: 91% !important;
   }
}

@media only screen and (min-width: 1080px) {
   // .main-menu-item-div {
   //    height: 35rem !important;
   // }
   .checkout-payment-modal {
      max-width: 80% !important;
   }
   .checkout-payment-card-modal {
      max-width: 40% !important;
   }
}
// this rule is specific to the KIOSK
@media only screen and (min-width: 1080px), screen and (min-height: 1900px) {
   //Actual font size of the message/text in alert
   .swal2-html-container > h1 {
      font-size: 3.5rem !important;
   }
   .swal2-html-container,
   .swal2-title {
      font-size: 3.5rem !important;
   }
   //size of the button
   .swal2-actions {
      width: 154px;
      height: 85px;
   }
   .swal2-loader {
      font-size: 2rem;
   }
   //Size of the actual icons
   .swal2-icon {
      &.swal2-success {
         font-size: 2.7rem;
      }
      &.swal2-warning {
         font-size: 2.7rem;
      }
      &.swal2-error {
         font-size: 2.7rem;
      }
   }
   .swal2-styled.swal2-confirm {
      font-size: 4.4rem;
   }
   //Size of the alert popup
   .swal2-container.swal2-center > .swal2-popup {
      width: 50rem !important; // using this !important to override the styles in the alerts file
   }
   .swal2-container.swal2-center.modal-full-width > .swal2-popup {
      width: 62rem !important; // using this !important to override the styles in the alerts file
   }
   
}

.btn.btn-theme-primary {
   color: #fff;
   border-color: var(--kt-theme-default-primary);
   background-color: var(--kt-theme-default-primary);
}

.form-check-custom {
   display: flex;
   align-items: center;
   padding-left: 0;
   margin: 0;

   .form-check-input {
      margin: 0;
      float: none;
      flex-shrink: 0;
   }

   .form-check-label {
      margin-left: 0.55rem;
   }

   &.form-check-solid {
      .form-check-input {
         border-style: solid;
         border-width: 4px;
         background-color: var(--kt-form-check-input-bg-solid);
         border-color: var(--kt-theme-default-primary);

         &:active,
         &:focus {
            filter: none;
            background-color: var(--kt-form-check-input-bg-solid);
         }

         &:checked {
            background-color: var(--kt-form-check-input-bg-solid);
         }
      }
   }
}
.accordion-button:not(.collapsed) {
   background-color: var(--bs-accordion-btn-bg);
   box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}

.footer-bg-color {
   background-color: var(--kt-header-fixed-bg-color) !important;
}

.accordion-button:not(.collapsed)::after {
   background-image: var(--bs-accordion-btn-active-icon);
   /* transform: var(--bs-accordion-btn-icon-transform); */
   transform: rotate(0deg) !important;
}

.accordion-button::after {
   transform: rotate(180deg) !important;
}

.header-tablet-and-mobile-fixed .header {
   height: 55px !important;
   min-height: 100px !important;
}

.react-simple-keyboard{
   position: absolute;
   z-index: 9999;
   bottom: 0;
   width: 100%;
   left: 0;
   background-color: var(--kt-card-bg) !important;
   color: var(--kt-headings-color) !important;
}

.react-simple-keyboard .hg-functionBtn{
   width: 20px;
}
.hg-theme-default .hg-button{
   background:var(--kt-modal-content-bg) !important;
   border-bottom: 1px solid var(--bs-secondary) !important;
}

.simple-keyboard.hg-theme-default {
   border: 5px solid var(--bg-color);   
 }
 
 .simple-keyboard.hg-layout-default .hg-button.hg-red {
   background: var(--bg-color);
   color: white;
 }
 .keyboard-input.keyboard-active{
   border: 3px solid var(--kt-primary) !important;
 }

@media only screen and (min-height: 1900px) {
   .mtp-30 {
      margin-top: 30% !important;
   }
   .mtp-40 {
      margin-top: 40% !important;
   }
   .mtp-32 {
      margin-top: 32% !important;
   }
   .checkout-payment-modal {
      max-width: 80% !important;
   }
   .checkout-payment-card-modal {
      max-width: 70% !important;
   }
   .success-screen {
      width: 45% !important;
   }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
   border-color: #f1416c !important;
}

.tns.tns-default [data-controls='prev'] {
   left: 5px !important;
   font-size: 50px !important;
}
.tns.tns-default [data-controls='next'] {
   right: 5px !important;
   font-size: 50px !important;
}

.mw-70 {
   max-width: 70% !important;
}

.mw-80 {
   max-width: 80% !important;
}
.mw-67 {
   max-width: 67% !important;
}

.checkout-payment-card-modal {
   max-width: 60%;
}

.w-30 {
   width: 30% !important;
}

.success-screen {
   width: 30%;
}

.tns.tns-default .tns-outer {
   margin: 0px 5rem !important;
}
