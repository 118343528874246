.giftcard-modal {
   max-width: 30% !important;
}

@media only screen and (min-height: 1900px) {
   .giftcard-modal {
      max-width: 55% !important;
   }
}

.ulGrid {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
   list-style: none;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
   .ulGrid {
      grid-template-columns: repeat(1, 1fr);
   }
}
@media only screen and (min-width: 768px) and (max-width: 1080px) {
   .ulGrid {
      grid-template-columns: repeat(3, 1fr);
   }
}

.order_complete_first {   
   border-radius: 50%;
   height: 50px;
   width: 50px;
   display: flex;
   text-align: center;
   margin: 0rem 1.2rem 0rem 1rem; 
   align-items: center;
   justify-content: center;
   font-size: 1.52rem;
}

.order_complete_second {   
   border-radius: 50%;
   height: 50px;
   width: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0rem 1.2rem 0rem 1rem;   
   font-size: 1.52rem;
}

.grab_order_txt {
   color: #57262b;
}

.order_complete {
   font-size: 5rem;
}
.order_txt {
   font-size: 2.5rem;
}

.col_offset {
   margin-left: 60%;
}
.order_bottom_button {
   height: auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
   /* .order_complete_first {
      margin: 0.5rem 0.5rem 0.5rem 2rem;
   } */
   .order_txt {
      font-size: 1.5rem;
   }
   .col_offset {
      margin-left: 10%;
   }
}

@media (min-width: 768px) and (max-width: 992px) {
   .col_offset {
      margin-left: 36.667%;
   }
}
@media (min-width: 1080px) {
   .order_bottom_button {
      height: 20%;
      margin-bottom: 5rem;
   }
}

.input_height_88 {
   height: 88px;
   font-size: 2rem;
}
