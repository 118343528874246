.debugger-box {
    display: flex;
    /*flex-direction: column;*/
    /*overflow-y: hidden;*/
    width: 45%;
    height: 25%;
    top: 10%;
    left: 0;
    color: black;
    z-index: 100;
}

.debugger-btn {
    /*left: 100%;*/
    color: black !important;
    width:100%;
}

.debug-yellow{
    background-color: #FABB05 !important;
}

.debug-red{
    background-color: #dc3545;
}

.debugger-console{
    background-color: white;
    overflow-y: auto;
    width: 70%;
}

.debugger-controls{
    display: flex;
    flex-direction: column;
    width: 30%;
}