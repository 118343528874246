//
// Footer
//

.footer {
   background-color: #151521;
   position: fixed;
   bottom: 1%;
   width: 100%;
}
